import React, { useContext } from "react";
import ModalContext from "./ModalContext";
import { Modal } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const ModalElement = (props) => {
  const { t } = useTranslation();
  const { modalState, setModalState } = useContext(ModalContext);

  const handleCloseModal = () => {
    setModalState({
      ...modalState,
      open: false,
    });
  };

  const primaryAction = modalState.primaryAction
    ? {
        destructive: modalState.isPrimaryDestructive,
        content: modalState.primaryActionLabel,
        onAction: modalState.primaryAction,
      }
    : null;

  const secondaryActionLabel =
    modalState.secondaryActionLabel !== ""
      ? modalState.secondaryActionLabel
      : t("generic.cancel");

  const modalMarkup = (
    <Modal
      open={modalState.open}
      onClose={handleCloseModal}
      title={modalState.title}
      message={modalState.message}
      primaryAction={primaryAction}
      secondaryActions={[
        {
          content: secondaryActionLabel,
          onAction: handleCloseModal,
        },
      ]}
    />
  );

  return modalMarkup;
};

export default ModalElement;
