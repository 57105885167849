import React from "react";
import { Route, Switch } from "react-router-dom";
import FramesetPage from "../../pages/FramesetPage";
import SwitchCustomerPage from "../../pages/SwitchCustomerPage";

// import { PageNotFound } from '../../pages/PageNotFound';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={FramesetPage} />
      <Route path="/switch" component={SwitchCustomerPage} />
    </Switch>
  );
};
