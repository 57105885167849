export const en = {
  general: {
    name: "Account Switcher",
    loading: "Loading...",
    logout: "Log out from Account Switcher",
    searchHelp: "Press enter after you entered your search",
    errors: {
      noAccess: {
        title: "You don't have access to this page",
        description:
          "Please try again, close this window and log in again through Shopify",
      },
      form: {
        fieldRequired: "This field is required",
        invalidEmailaddress: "The email address is not valid",
      },
    },
  },
  menu: {
    about: "About this app",
    login: "Login as Customer",
    developedBy: "Developed by CODE",
    agent: "Agent",
    superAgent: "Super Agent",
  },
  loginAsCustomer: {
    title: "Login as Customer",
    tabs: {
      all: {
        title: "All",
        longTitle: "All customers",
      },
      assigned: {
        title: "Assigned",
        longTitle: "Only my assigned customers",
      },
    },
  },
  customers: {
    singular: "customer",
    plural: "customers",
    login: "Log in",
    addCustomer: "Add customer",
    switched: "Switched Identity",
    sort: {
      nameAsc: "Name Ascending",
      nameDesc: "Name Descending",
    },
    noEmail: "Email address is required",
    error: {
      title: "Customers overview could not be loaded",
      description:
        "Please refresh the page to try again. Otherwise try to log-in again through Shopify",
    },
    add: {
      title: "Create a new Customer",
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      phoneNumber: "Phone number",
      notifyCustomer: "Notify the customer",
      notifyCustomerHelpText:
        "The customer will be notified by email. Note that if you shop on behalf of this customer the create password link will be scrambled. So the customer will have to ask for a new one.",
      consentCustomer:
        "The customer has given approval that I create an account",
      consentCustomerHelpText:
        "Make sure that the customer has given her or his approval that you create an account on behalf of her/him.",
      submitButton: "Create customer",
      cancelButton: "Cancel",
      successMessage:
        "Customer is successfully created. It might take a few moments before it shows up.",
      failedMessage:
        "Unable to create customer, please double check your input. Shopify reported: {{message}}",
      errors: {
        "customer-already-exists":
          "Customer with this email address already exists",
      },
    },
  },
  switch: {
    title: "Switching identities",
    description: "Please wait while logging in...",
  },
};
