import React from "react";
import { Frame, Spinner, TextContainer, TextStyle } from "@shopify/polaris";
import "./index.css";

// import { PageNotFound } from '../../pages/PageNotFound';

export const Loading = (props) => {
  return (
    <Frame>
      <div className="loadingSpinner">
        <div className="spinner">
          <div>
            <Spinner />
          </div>
          <TextContainer>
            <p>
              <TextStyle variation="subdued" children={props.text} />
            </p>
          </TextContainer>
        </div>
      </div>
    </Frame>
  );
};
