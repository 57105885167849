import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withFirebase } from "../../firebase";
import { createBrowserHistory } from "history";
import { AuthError } from "./AuthError";
import { AppConfigProvider } from "../../contexts/AppConfigContext/AppConfigProvider";
import { AppRoutes } from "../Routing/AppRoutes";
import { Loading } from "../Loading";

const history = createBrowserHistory();

const Authorize = ({ firebase }) => {
  const { t } = useTranslation();
  const [errorCode, setErrorCode] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loginUser = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
          const isAuthenticated = await firebase.authenticate(token);
          if (!isAuthenticated) throw new Error("FIRESTORE");
          setIsLoggedIn(true);
          setInitialized(true);
          history.push("/");
        } else {
          await firebase.isLoggedIn(
            setIsLoggedIn,
            setInitialized,
            setErrorCode
          );
        }
      } catch (error) {
        setErrorCode(error);
      }
    };
    loginUser();
  }, [firebase]);

  const initializedMarkup =
    !errorCode && isLoggedIn ? (
      <AppConfigProvider firebase={firebase}>
        <AppRoutes firebase={firebase} />
      </AppConfigProvider>
    ) : (
      <AuthError errorCode={errorCode} />
    );

  return initialized ? (
    initializedMarkup
  ) : (
    <Loading text={t("general.loading")} />
  );
};

export default withFirebase(Authorize);
