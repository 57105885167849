export const fr = {
  general: {
    name: "Account Switcher",
    loading: "Chargement...",
    logout: "Déconnectez-vous de Account Switcher",
    searchHelp: "Appuyez sur Entrée après avoir entré votre recherche",
    errors: {
      noAccess: {
        title: "Vous n'avez pas accès à cette page",
        description:
          "Veuillez réessayer, fermer cette fenêtre et vous reconnecter via Shopify",
      },
      form: {
        fieldRequired: "Ce champ est requis",
        invalidEmailaddress: "S'il vous plaît, mettez une adresse email valide",
      },
    },
  },
  menu: {
    about: "À propos de cette application",
    login: "Connectez-vous en tant que client",
    developedBy: "Fabriqué par CODE",
    agent: "Agent",
    superAgent: "Superagent",
  },
  loginAsCustomer: {
    title: "Connectez-vous en tant que client",
    tabs: {
      all: {
        title: "Tout",
        longTitle: "Tout les clients",
      },
      assigned: {
        title: "Attribué",
        longTitle: "Clients assignés",
      },
    },
  },
  customers: {
    singular: "client",
    plural: "clients",
    login: "Connexion",
    addCustomer: "Créer un client",
    switched: "Switched Identity",
    sort: {
      nameAsc: "Nom Ascendant",
      nameDesc: "Nom Descendant",
    },
    noEmail: "Adresse e-mail est nécessaire",
    error: {
      title: "L'aperçu du client n'a pas pu être chargé",
      description:
        "Veuillez actualiser la page pour réessayer. Sinon, essayez de vous reconnecter via Shopify",
    },
    add: {
      title: "Créer un nouveau client",
      firstName: "Prénom",
      lastName: "Nom de famille",
      email: "Email",
      phoneNumber: "Numéro de téléphone",
      notifyCustomer: "Notification client",
      notifyCustomerHelpText:
        "Le client est informé par e-mail qu'un compte client a été créé pour lui. L'e-mail contient un lien permettant de créer un mot de passe pour le compte client.",
      consentCustomer: "Le client a approuvé la création d'un compte client",
      consentCustomerHelpText:
        "Le client a accepté que je lui crée un compte client personnel, auquel j'ai accès pour passer des commandes en son nom.",
      submitButton: "Créer un client",
      cancelButton: "Annuler",
      successMessage:
        "Nouveau client créé avec succès. Shopify peut prendre une minute pour traiter le nouveau client.",
      failedMessage:
        "Il semble y avoir un problème lors de la tentative de création d'un nouveau client: {{message}}",
      errors: {
        "customer-already-exists":
          "Le client avec cette adresse e-mail existe déjà",
      },
    },
  },
  switch: {
    title: "Changer de compte",
    description: "Veuillez patienter pendant la connexion...",
  },
};
