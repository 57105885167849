import React, { useContext, useState, useCallback } from "react";
import { Frame, Layout, Page } from "@shopify/polaris";
import { CustomerPlusMajor } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import Customer from "../components/Customers";

import { ToastProvider, ToastElement } from "../contexts/ToastContext";
import { ModalProvider, ModalElement } from "../contexts/ModalContext";
import AppNavigation from "../components/Navigation";
import { logo } from "../components/Frame/logo";
import TopBar from "../components/Frame/TopBar";
import { NewCustomer } from "../components/Customers/NewCustomer";
import { AppConfigContext } from "../contexts/AppConfigContext/AppConfigContext";
import { withFirebase } from "../firebase";
import { AppConfig } from "../types/AppConfig";

const FramesetPage = ({ firebase }) => {
  const { t } = useTranslation();
  const { config } = useContext(AppConfigContext);
  const genericConfig = config[AppConfig.Generic.id] || {};
  const [modalActive, setModalActive] = useState(false);
  const handleModalChange = useCallback(
    () => setModalActive(!modalActive),
    [modalActive]
  );
  const primaryAction = genericConfig.allowCreateAccount
    ? {
        content: t("customers.addCustomer"),
        icon: CustomerPlusMajor,
        onClick: setModalActive,
      }
    : false;

  return (
    <Frame
      logo={logo}
      topBar={<TopBar firebase={firebase} />}
      navigation={<AppNavigation />}
    >
      <ToastProvider>
        <ModalProvider>
          <ToastElement />
          <ModalElement />
          <Page
            fullWidth
            title={t("loginAsCustomer.title")}
            primaryAction={primaryAction}
          >
            <Layout>
              <Layout.Section>
                {genericConfig.allowCreateAccount && (
                  <NewCustomer
                    firebase={firebase}
                    modalActive={modalActive}
                    handleChange={handleModalChange}
                    // open={isNewCustomerSheetOpen}
                    // toggle={toggleNewCustomerSheet}
                    // setCreateCustomerSuccess={setCreateCustomerSuccess}
                  />
                )}
                <Customer />
              </Layout.Section>
            </Layout>
          </Page>
        </ModalProvider>
      </ToastProvider>
    </Frame>
  );
};

export default withFirebase(FramesetPage);
