import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyState, Frame, Page } from "@shopify/polaris";

import NoAccess from "./401.png";

export const AuthError = () => {
  const { t } = useTranslation();

  return (
    <Frame>
      <Page>
        <EmptyState
          heading={t("general.errors.noAccess.title")}
          fullWidth
          image={NoAccess}
        >
          <p>{t("general.errors.noAccess.description")}</p>
        </EmptyState>
      </Page>
    </Frame>
  );
};
