export const es = {
  general: {
    name: "Account Switcher",
    loading: "Sobreprima...",
    logout: "Salir de Account Switcher",
    searchHelp: "Presiona enter después de ingresar tu búsqueda",
    errors: {
      noAccess: {
        title: "No tienes acceso a esta página",
        description:
          "Vuelva a intentarlo, cierre esta ventana e inicie sesión de nuevo a través de Shopify",
      },
      form: {
        fieldRequired: "Este campo es obligatorio",
        invalidEmailaddress: "La dirección de correo electrónico no es válida",
      },
    },
  },
  menu: {
    about: "Acerca de esta aplicación",
    login: "Inicia sesión como cliente",
    developedBy: "Hecho por CODE",
    agent: "Agente",
    superAgent: "Súper agente",
  },
  loginAsCustomer: {
    title: "Inicia sesión como cliente",
    tabs: {
      all: {
        title: "Todos",
        longTitle: "Todos los clientes",
      },
      assigned: {
        title: "Asignado",
        longTitle: "Clientes asignados",
      },
    },
  },
  customers: {
    singular: "cliente",
    plural: "clientes",
    login: "Iniciar sesión",
    addCustomer: "Agregar cliente",
    switched: "Identidad cambiada",
    sort: {
      nameAsc: "Nombre Ascendente",
      nameDesc: "Nombre Descendente",
    },
    noEmail: "Se requiere Dirección de correo electrónico",
    error: {
      title: "Error al cargar la descripción general del cliente",
      description:
        "Actualiza la página para volver a intentarlo. De lo contrario, intente iniciar sesión nuevamente a través de Shopify",
    },
    add: {
      title: "Crear un nuevo cliente",
      firstName: "Primer nombre",
      lastName: "Apellido",
      email: "Dirección de correo electrónico",
      phoneNumber: "Número de teléfono",
      notifyCustomer: "Notificar al cliente",
      notifyCustomerHelpText:
        "El cliente será notificado por correo electrónico.Tenga en cuenta que si compra en nombre de este cliente, el enlace de creación de contraseña se codificará. Así que el cliente tendrá que pedir uno nuevo.",
      consentCustomer:
        "El cliente ha dado su aprobación para que cree una cuenta.",
      consentCustomerHelpText:
        "Asegúrese de que el cliente haya dado su aprobación para que cree una cuenta en su nombre.",
      submitButton: "Crear cliente",
      cancelButton: "Cancelar",
      successMessage:
        "El cliente se crea exitosamente. Puede tomar unos momentos antes de que aparezca.",
      failedMessage:
        "No se puede crear el cliente, verifique dos veces su entrada. Shopify reportado: {{message}}",
      errors: {
        "customer-already-exists":
          "El cliente con esta dirección de correo electrónico ya existe",
      },
    },
  },
  switch: {
    title: "Cambiar de identidad",
    description: "Por favor, espere al iniciar sesión...",
  },
};
