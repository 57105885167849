export const de = {
  general: {
    name: "Account Switcher",
    loading: "Seite wird geladen",
    logout: "Ausloggen von Account Switcher",
    searchHelp: "Drücken Sie Enter, nachdem Sie Ihre Suche eingegeben haben",
    errors: {
      noAccess: {
        title: "Sie haben keinen Zugriff auf diese Seite",
        description:
          "Bitte versuchen Sie es erneut, schließen Sie dieses Fenster und melden Sie sich erneut über Shopify an",
      },
      form: {
        fieldRequired: "Dieses Feld wird benötigt",
        invalidEmailaddress: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      },
    },
  },
  menu: {
    about: "Über diese App",
    login: "Melden Sie sich als Kunde an",
    developedBy: "Entwickelt von CODE",
    agent: "Agent",
    superAgent: "Super-Agent",
  },
  loginAsCustomer: {
    title: "Melden Sie sich als Kunde an",
    tabs: {
      all: {
        title: "Alle",
        longTitle: "Alle Kunden",
      },
      assigned: {
        title: "Zugewiesen",
        longTitle: "Zugewiesen Kunden",
      },
    },
  },
  customers: {
    singular: "Kunde",
    plural: "Kunden",
    login: "Anmeldung",
    addCustomer: "Kunde hinzufügen",
    switched: "Wechselnde Identität",
    sort: {
      nameAsc: "Name aufsteigend",
      nameDesc: "Name absteigend",
    },
    noEmail: "E-Mailadresse wird benötigt",
    error: {
      title: "Kundenübersicht konnte nicht geladen werden",
      description:
        "Bitte aktualisieren Sie die Seite, um es erneut zu versuchen. Versuchen Sie andernfalls, sich erneut über Shopify anzumelden",
    },
    add: {
      title: "Erstellen Sie einen neuen Kunden",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail-Addresse",
      phoneNumber: "Telefonnummer",
      notifyCustomer: "Benachrichtigung Kunde",
      notifyCustomerHelpText:
        "Der Kunde wird per E-Mail benachrichtigt, dass ein Kundenkonto für ihn erstellt wurde. Die E-Mail enthält einen Link, um ein Passwort für das Kundenkonto zu erstellen.",
      consentCustomer: "Der Kunde hat zugestimmt, dass ich ein Konto erstelle",
      consentCustomerHelpText:
        "Der Kunde hat zugestimmt, dass ich ein persönliches Kundenkonto für ihn erstelle, worauf ich Zugriff habe um Bestellungen in seinem Namen aufzugeben.",
      submitButton: "Kunden erstellen",
      cancelButton: "Abbrechen",
      successMessage:
        "Neuer Kunde erfolgreich erstellt. Es kann eine Minute dauern, bis Shopify den neuen Kunden bearbeitet.",
      failedMessage:
        "Beim Versuch, einen neuen Kunden zu erstellen, scheint ein Problem zu bestehen: {{message}}",
      errors: {
        "customer-already-exists":
          "Der Kunde mit dieser E-Mail-Adresse ist bereits vorhanden",
      },
    },
  },
  switch: {
    title: "Identitäten wechseln",
    description: "Einen Moment bitte...",
  },
};
