import React, { useEffect, useState } from "react";
import {
  Frame,
  Layout,
  Page,
  Card,
  TextContainer,
  Banner,
  ProgressBar,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { withFirebase } from "../firebase";
import { logo } from "../components/Frame/logo";
import TopBar from "../components/Frame/TopBar";

const SwitchCustomerPage = ({ firebase }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(85);

  useEffect(() => {
    if (progress < 100) {
      setTimeout(() => setProgress(progress + 1), 1000);
    }
  }, [progress]);

  return (
    <Frame logo={logo} topBar={<TopBar firebase={firebase} />}>
      <Page>
        <Layout>
          <Layout.Section>
            <Card title={t("switch.title")} sectioned>
              <TextContainer>
                <Banner status="info">
                  <p>{t("switch.description")}</p>
                </Banner>
                <ProgressBar progress={progress} size="small" />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  );
};

export default withFirebase(SwitchCustomerPage);
