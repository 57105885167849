import React, { useCallback, useEffect, useState } from "react";
import { AppConfigContext } from "./AppConfigContext";
import { AppConfig } from "../../types/AppConfig";

export const AppConfigProvider = ({ firebase, children }) => {
  const [appConfig, setAppConfig] = useState({});
  const [isFetchingConfig, setIsFetchingConfig] = useState(false);

  const updateConfigState = (configData, configId) =>
    setAppConfig((prevState) => {
      let newState = {};
      newState = {
        ...prevState,
        ...(configId
          ? {
              [configId]: {
                ...prevState[configId],
                ...configData,
              },
            }
          : configData),
      };

      return newState;
    });

  const fetchAppConfig = useCallback(async () => {
    const configKeys = Object.keys(AppConfig).map((key) => AppConfig[key].id);
    let configData = Object.keys(AppConfig).reduce((config, key) => {
      config[AppConfig[key].id] = AppConfig[key].defaults;
      return config;
    }, {});

    try {
      for (let i = 0; i < configKeys.length; i++) {
        const configDoc = await firebase.specificConfig(configKeys[i]).get();

        if (configDoc.exists) {
          const data = configDoc.data();
          if (configKeys[i] === "generic") {
            delete data.appSecret;
            delete data.multipassToken;
            delete data.redirectUrl;
            delete data.snippetsInstalled;
          }
          configData[configKeys[i]] = {
            ...configData[configKeys[i]],
            ...data,
          };
        }
      }
    } catch (error) {
      console.error("Error fetching app config data:", error);
    } finally {
      updateConfigState(configData);
      setIsFetchingConfig(false);
    }
  }, [firebase]);

  // Fetch the initial data only once when the app is loaded
  // eslint-disable-next-line
  useEffect(() => {
    fetchAppConfig();
  }, [fetchAppConfig]);

  return (
    <AppConfigContext.Provider
      value={{
        config: appConfig,
      }}
    >
      {isFetchingConfig ? <div>Loading...</div> : children}
    </AppConfigContext.Provider>
  );
};
