import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Card, Tabs } from "@shopify/polaris";
import CustomersList from "./CustomersList";
import { withFirebase } from "../../firebase/context";
import { AppConfigContext } from "../../contexts/AppConfigContext/AppConfigContext";
import { AppConfig } from "../../types/AppConfig";

const Customer = ({ firebase }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const { config } = useContext(AppConfigContext);
  const genericConfig = config[AppConfig.Generic.id] || {};

  const allowSwitchToAll = genericConfig.allowSwitchToAll || false;

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );
  const tabs = [
    {
      id: "assigned",
      content: t("loginAsCustomer.tabs.assigned.title"),
      accessibilityLabel: t("loginAsCustomer.tabs.assigned.longTitle"),
      panelID: "assigned-customers",
    },
  ];

  if (allowSwitchToAll && firebase.userData.isSuperAgent === false) {
    tabs.push({
      id: "all",
      content: t("loginAsCustomer.tabs.all.title"),
      accessibilityLabel: t("loginAsCustomer.tabs.all.longTitle"),
      panelID: "all-customers",
    });
  }

  return (
    <React.Fragment>
      <Card>
        <Tabs
          tabs={tabs.length > 1 ? tabs : []}
          selected={selected}
          onSelect={handleTabChange}
        />
        <CustomersList
          selectedTab={tabs[selected]}
          allowSwitchToAll={allowSwitchToAll}
        />
      </Card>
    </React.Fragment>
  );
};

export default withFirebase(Customer);
