import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Set Firebase config variables
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

class Firebase {
  constructor() {
    if (!app.apps.length) {
      app.initializeApp(config);
    }

    // Firebase APIs
    this.auth = app.auth();
    this.db = app.firestore();
    this.fieldValue = app.firestore.FieldValue;
    this.authPersistence = app.auth.Auth.Persistence.SESSION;

    // User data
    this.userData = {
      agentId: "",
      app: "-",
      name: "-",
      shop: "-",
      isSuperAgent: false,
      token: "",
    };
  }

  // User data store API
  setUserData = (idTokenResult) => {
    this.userData = {
      agentId: idTokenResult.claims.agent_id,
      app: idTokenResult.claims.aud,
      name: idTokenResult.claims.name,
      shop: idTokenResult.claims.shop,
      isSuperAgent: idTokenResult.claims.super_agent,
      token: idTokenResult.token,
    };
  };

  // Authenticate with firebase
  authenticate = async (customToken) => {
    try {
      await this.auth.setPersistence(this.authPersistence);
      // Wait for the sign in to firebase using the received custom token
      await this.auth.signInWithCustomToken(customToken);
      // Wait for the user data
      const tokenResult = await this.auth.currentUser.getIdTokenResult();
      this.setUserData(tokenResult);

      return true;
    } catch (error) {
      console.error("Firebase auth error:", error);
      return false;
    }
  };

  // Check if login is still valid
  isLoggedIn = async (isLoggedIn, isInitialized) => {
    try {
      this.auth.onAuthStateChanged(async (user) => {
        if (user) {
          const tokenResult = await user.getIdTokenResult();
          this.setUserData(tokenResult);
          isLoggedIn(true);
          isInitialized(true);
        } else {
          isLoggedIn(false);
          isInitialized(true);
        }
      });
    } catch (error) {
      console.error("Firebase auth error:", error);
      return false;
    }
  };

  // App config API
  specificConfig = (id) =>
    this.db.doc(`sfyShops/${this.userData.shop}/config/${id}`);
}

export default Firebase;
