export const AppConfig = {
  Generic: {
    id: "generic",
    defaults: {
      allowSwitchToAll: false,
      allowCreateAccount: false,
      clearCartAfterSwitch: false,
    },
  },
};
