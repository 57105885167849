import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  Form,
  Modal,
  FormLayout,
  TextField,
  Checkbox,
  ButtonGroup,
  Button,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import ToastContext from "../../contexts/ToastContext/ToastContext";

export const NewCustomer = ({ firebase, modalActive, handleChange }) => {
  const { t } = useTranslation();
  const { dispatchToast } = useContext(ToastContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    sendConfirmation: false,
  });
  const [confirmationEmail, setConfirmationEmail] = useState(true);
  const [consent, setConsent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const handleConfirmationChange = useCallback(
    (newChecked) => {
      setConfirmationEmail(newChecked);
      setFormData({
        ...formData,
        sendConfirmation: newChecked,
      });
    },
    [formData]
  );
  const handleConsentChange = useCallback(
    (newChecked) => setConsent(newChecked),
    []
  );

  const handleFieldChange = useCallback(
    (value, fieldName) => {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    },
    [formData]
  );

  const handleFormValidation = useCallback(() => {
    // validate form fields
    let validation = {};

    const requiredFields = ["firstName", "lastName"];
    const emailField = "email";

    Object.keys(formData).forEach((key) => {
      let validationMessage = null;

      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (requiredFields.includes(key)) {
        if (formData[key] === "") {
          validationMessage = t("general.errors.form.fieldRequired");
        }
      }
      if (key === emailField) {
        if (!formData[key].toLowerCase().match(emailRegex)) {
          validationMessage = t("general.errors.form.invalidEmailaddress");
        }
      }

      // check if empty
      if (validationMessage) {
        validation = {
          ...validation,
          [key]: validationMessage,
        };
      }
    });

    // set validation to state
    setFormValidation(validation);

    // return true if form invalid
    if (
      Object.keys(validation).length > 0 &&
      validation.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  }, [formData, t]);

  const handleSubmit = useCallback(async () => {
    if (handleFormValidation()) {
      return;
    }

    let dataResponse;

    try {
      setIsSubmitting(true);

      // get an ID token
      const idToken = await firebase.auth.currentUser.getIdToken();

      const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URI}/agent/customer/create`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-shopify-account-switcher": idToken,
        },
        body: JSON.stringify(formData),
      };

      const data = await fetch(url, options);
      dataResponse = await data.json();
      if (data.status !== 200) throw new Error("Unable to create customer");

      dispatchToast(t("customers.add.successMessage"));
      handleChange();
    } catch (error) {
      if (dataResponse.message === "customer-already-exists") {
        dispatchToast(t(`customers.add.errors.${dataResponse.message}`), {
          isError: true,
        });
      } else {
        dispatchToast(
          t(`customers.add.failedMessage`, {
            message: dataResponse.detail.join(", "),
          }),
          {
            isError: true,
            duration: 5000,
          }
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [
    dispatchToast,
    firebase.auth.currentUser,
    formData,
    handleChange,
    handleFormValidation,
    t,
  ]);

  // clean when closed
  useEffect(() => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sendConfirmation: false,
    });
    setConsent(false);
    setConfirmationEmail(true);
    setIsSubmitting(false);
    setFormValidation({});
  }, [modalActive]);

  return (
    <Modal
      open={modalActive}
      title={t("customers.add.title")}
      onClose={!isSubmitting ? handleChange : undefined}
    >
      <Form preventDefault>
        <Modal.Section>
          <FormLayout>
            <TextField
              label={
                <label className="settings-fields-label">
                  {t("customers.add.firstName")}
                </label>
              }
              type="text"
              disabled={isSubmitting}
              value={formData["firstName"] || ""}
              id="firstName"
              requiredIndicator={true}
              error={formValidation["firstName"]}
              onChange={handleFieldChange}
            />
            <TextField
              label={
                <label className="settings-fields-label">
                  {t("customers.add.lastName")}
                </label>
              }
              type="text"
              disabled={isSubmitting}
              value={formData["lastName"] || ""}
              id="lastName"
              requiredIndicator={true}
              error={formValidation["lastName"]}
              onChange={handleFieldChange}
            />
            <TextField
              label={
                <label className="settings-fields-label">
                  {t("customers.add.email")}
                </label>
              }
              type="email"
              disabled={isSubmitting}
              value={formData["email"] || ""}
              id="email"
              requiredIndicator={true}
              error={formValidation["email"]}
              onChange={handleFieldChange}
            />
            <TextField
              label={
                <label className="settings-fields-label">
                  {t("customers.add.phoneNumber")}
                </label>
              }
              type="text"
              disabled={isSubmitting}
              value={formData["phone"] || ""}
              id="phone"
              onChange={handleFieldChange}
            />
          </FormLayout>
        </Modal.Section>
        <Modal.Section>
          <FormLayout>
            <Checkbox
              label={t("customers.add.notifyCustomer")}
              helpText={t("customers.add.notifyCustomerHelpText")}
              checked={confirmationEmail}
              disabled={isSubmitting}
              onChange={handleConfirmationChange}
            />
            <Checkbox
              label={t("customers.add.consentCustomer")}
              helpText={t("customers.add.consentCustomerHelpText")}
              checked={consent}
              disabled={isSubmitting}
              onChange={handleConsentChange}
            />
          </FormLayout>
        </Modal.Section>
        <Modal.Section subdued>
          <ButtonGroup>
            <Button
              primary
              children={t("customers.add.submitButton")}
              disabled={!consent}
              loading={isSubmitting}
              onClick={handleSubmit}
            />
            <Button
              onClick={!isSubmitting ? handleChange : undefined}
              children={t("customers.add.cancelButton")}
              disabled={isSubmitting}
            />
          </ButtonGroup>
        </Modal.Section>
      </Form>
    </Modal>
  );
};
