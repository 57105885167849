import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { de } from "./translations/de";
import { en } from "./translations/en";
import { es } from "./translations/es";
import { fr } from "./translations/fr";
import { nl } from "./translations/nl";

const translations = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
};

const detectorOptions = {
  // order and from where user language should be detected
  order: ["querystring", "localStorage", "cookie", "navigator"],
  lookupQuerystring: "lang",

  // cache user language
  caches: ["localStorage", "cookie"],
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: detectorOptions,
    resources: translations,
    whitelist: ["en", "nl", "fr", "de", "es"],
    nonExplicitWhitelist: true,
    fallbackLng: "en",
    cleanCode: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
