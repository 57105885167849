export const nl = {
  general: {
    name: "Account Switcher",
    loading: "Laden...",
    logout: "Uitloggen van Account Switcher",
    searchHelp: "Druk enter nadat je een zoekopdracht hebt gegeven",
    errors: {
      noAccess: {
        title: "Je hebt geen toegang tot deze pagina",
        description: "Probleer het opnieuw of log opnieuw in via Shopify.",
      },
      form: {
        fieldRequired: "Verplicht veld",
        invalidEmailaddress: "E-mailadres is niet geldig",
      },
    },
  },
  menu: {
    about: "Over deze app",
    login: "Inloggen als klant",
    developedBy: "Ontwikkeld door CODE",
    agent: "Agent",
    superAgent: "Super Agent",
  },
  loginAsCustomer: {
    title: "Inloggen als klant",
    tabs: {
      all: {
        title: "Alle",
        longTitle: "Alle klanten",
      },
      assigned: {
        title: "Toegewezen",
        longTitle: "Alleen mijn toegewezen klanten",
      },
    },
  },
  customers: {
    singular: "klant",
    plural: "klanten",
    login: "Inloggen",
    addCustomer: "Nieuwe klant",
    switched: "Gewisselde identiteit",
    sort: {
      nameAsc: "Naam Oplopend",
      nameDesc: "Naam Aflopend",
    },
    noEmail: "E-mailadres is verplicht",
    error: {
      title: "Klanten overzicht kon niet worden geladen",
      description:
        "Vernieuw de pagina om het opnieuw te proberen. Mocht dat ook niet werken dan moet je via Shopify opnieuw inloggen",
    },
    add: {
      title: "Nieuwe klant",
      firstName: "Voornaam",
      lastName: "Achternaam",
      email: "E-mailadres",
      phoneNumber: "Telefoonnummer",
      notifyCustomer: "Verwittig de klant",
      notifyCustomerHelpText:
        "De klant wordt via een e-mail op de hoogte gebracht. Let wel dat als je direct inlogt de klant geen gebruik meer kan maken van de link in de e-mail. De klant zal vragen om een nieuwe invite..",
      consentCustomer:
        "De klant heeft toestemming gegeven dat ik een account aanmaak",
      consentCustomerHelpText:
        "Zorg ervoor dat je toestemming hebt van de klant voordat je deze aanmaakt.",
      submitButton: "Klant aanmaken",
      cancelButton: "Annuleren",
      successMessage:
        "De klant is succesvol aangemaakt, het kan een paar minuten duren voordat deze in Shopify is te zien",
      failedMessage:
        "Klant kon niet worden aangemaakt. Shopify rapporteerde deze fout(en): {{message}}",
      errors: {
        "customer-already-exists": "Er is al een klant met dit e-mailadres",
      },
    },
  },
  switch: {
    title: "Wisselen van identiteit",
    description: "Een ogenblik geduld a.u.b...",
  },
};
