import React, { useContext } from "react";
import ToastContext from "./ToastContext";
import { Toast } from "@shopify/polaris";

const ToastElement = (props) => {
  const { toastState, setToastState } = useContext(ToastContext);

  const handleHideToast = () =>
    setToastState({
      ...toastState,
      show: false,
    });

  const toastMarkup = toastState.show && (
    <Toast
      content={toastState.content}
      onDismiss={handleHideToast}
      duration={toastState.duration}
      error={toastState.isError}
    />
  );

  return toastMarkup;
};

export default ToastElement;
