import React, { useState, useCallback } from "react";
import { TopBar as ShopifyTopBar } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
// import { LogOutMinor } from "@shopify/polaris-icons";
import { getInitials } from "../../util/getInitials";
import { withRouter } from "react-router-dom";

const TopBar = ({ firebase, history }) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const { t } = useTranslation();
  const userMenuMarkup = (
    <ShopifyTopBar.UserMenu
      // actions={[
      //   {
      //     items: [
      //       {
      //         content: t("general.logout"),
      //         icon: LogOutMinor,
      //         url: "/logout",
      //       },
      //     ],
      //   },
      // ]}
      name={firebase.userData.name}
      detail={
        firebase.userData.isSuperAgent ? t("menu.superAgent") : t("menu.agent")
      }
      initials={getInitials(firebase.userData.name)}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  return (
    <ShopifyTopBar userMenu={userMenuMarkup} showNavigationToggle={true} />
  );
};

export default withRouter(TopBar);
