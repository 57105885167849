import React, { useCallback, useState, useEffect } from "react";
import { withFirebase } from "../../firebase/context";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useTranslation } from "react-i18next";
import {
  Badge,
  Filters,
  Pagination,
  ResourceList,
  ResourceItem,
  TextStyle,
  Stack,
  Button,
  EmptyState,
  Icon,
} from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { detectMobile } from "../../util/detectMobile";
import "./CustomersList.css";
import ErrorImage from "../../assets/400.png";

const CustomersList = ({ firebase, selectedTab, allowSwitchToAll }) => {
  const { t } = useTranslation();
  const [queryValue, setQueryValue] = useState("");
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
    setQuery("");
    setHasQuery(false);
  }, []);

  const [queryFocus, setQueryFocus] = useState(false);

  const [loggedInCustomerId, setLoggedInCustomerId] = useState(
    sessionStorage.getItem("account-switcher-customer-id")
  );
  const [hasNextPage, setHasNextPage] = useState(false);
  const [firstCursor, setFirstCursor] = useState("");
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasQuery, setHasQuery] = useState(false);
  const [lastCursor, setLastCursor] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("NAME_ASC"); // @TODO: perhaps easy to implement

  const enterPressed = useKeyPress("Enter");

  useEffect(() => {
    setShowAll(selectedTab.id === "all" && allowSwitchToAll);
  }, [selectedTab.id, allowSwitchToAll]);

  useEffect(() => {
    if (queryFocus === true && enterPressed) {
      if (queryValue.length === 0) setHasQuery(false);
      setQuery(queryValue);
    }
  }, [queryValue, queryFocus, enterPressed]);

  const customerData = useCallback(
    async (paginationParams) => {
      if (queryValue && query !== queryValue) return;
      if (!queryValue && query) return;

      setHasNextPage(false);
      setHasPreviousPage(false);
      setIsLoading(true);
      setHasError(false);
      const queryParams = new URLSearchParams();
      queryParams.append("shop", firebase.userData.shop);
      queryParams.append("agent_id", firebase.userData.agentId);
      queryParams.append(
        "reversed",
        sortOrder === "NAME_DESC" ? "true" : "false"
      );
      if (query) {
        queryParams.append("query", query);
        setHasQuery(true);
      }

      if (paginationParams) {
        if (paginationParams["before"])
          queryParams.append("before", paginationParams["before"]);
        if (paginationParams["after"])
          queryParams.append("after", paginationParams["after"]);
      }
      if (showAll) {
        queryParams.append("show_all", "true");
      }

      try {
        const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URI}/agent/customers?${queryParams}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-shopify-account-switcher": firebase.userData.token,
          },
        };

        const data = await fetch(url, options);
        if (data.status !== 200)
          throw new Error("Something went wrong, please try again");
        const customerDataResponse = await data.json();

        // update states
        setCustomers(customerDataResponse.edges.map((edge) => edge.node));
        setHasNextPage(customerDataResponse.pageInfo.hasNextPage);
        setHasPreviousPage(customerDataResponse.pageInfo.hasPreviousPage);
        setFirstCursor(
          customerDataResponse.edges.length > 0
            ? customerDataResponse.edges[0].cursor
            : ""
        );
        setLastCursor(
          customerDataResponse.edges.length > 0
            ? customerDataResponse.edges[customerDataResponse.edges.length - 1]
                .cursor
            : ""
        );
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [firebase.userData, queryValue, query, showAll, sortOrder]
  );

  useEffect(() => {
    customerData();
  }, [customerData]);

  const queryUpdate = useCallback((input) => {
    setQueryValue(input);
  }, []);

  const goToPreviousPage = useCallback(
    () => customerData({ before: firstCursor }),
    [customerData, firstCursor]
  );

  const goToNextPage = useCallback(
    () => customerData({ after: lastCursor }),
    [customerData, lastCursor]
  );

  // Callback to make the switch to another user
  const switchUser = useCallback(
    async (customerId, customerName) => {
      setIsLoading(true);

      // get the current window
      const currentWindow = window;
      const isMobile = detectMobile();

      // name the browser window
      const browserWindowName = "IdentitySwitcher";

      // in order to prevent popup blocking, open directly a window on the user's action
      const customerWindow = window.open("/switch", browserWindowName);

      try {
        // loggedInCustomerId
        // get an ID token
        const idToken = await firebase.auth.currentUser.getIdToken();

        const url = `${
          process.env.REACT_APP_CLOUD_FUNCTIONS_URI
        }/agent/multipass?shop=${
          firebase.userData.shop
        }&customer_id=${customerId.replace("gid://shopify/Customer/", "")}`;
        const options = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-shopify-account-switcher": idToken,
          },
        };

        const data = await fetch(url, options);
        if (data.status !== 200)
          throw new Error(`Unable to obtain Multipass URL`);
        const customerDataResponse = await data.json();

        // put in the window that we just opened
        window.open(customerDataResponse.url, browserWindowName);
        window.focus(); // force focus of the window

        sessionStorage.setItem("account-switcher-customer-name", customerName);
        sessionStorage.setItem("account-switcher-customer-id", customerId);

        // mark as selected
        setLoggedInCustomerId(customerId);

        if (isMobile === true) currentWindow.close();
      } catch (error) {
        console.error(error);
        customerWindow.close();
      } finally {
        setIsLoading(false);
      }
    },
    [firebase.auth.currentUser, firebase.userData.shop]
  );

  const resourceName = {
    singular: t("customers.singular"),
    plural: t("customers.plural"),
  };

  const filterControl = (
    <React.Fragment>
      <Filters
        queryValue={queryValue}
        onQueryChange={queryUpdate}
        filters={[]}
        disabled={isLoading}
        onQueryClear={handleQueryValueRemove}
        onQueryFocus={() => setQueryFocus(true)}
        onQueryBlur={() => setQueryFocus(false)}
        onClearAll={handleQueryValueRemove}
      />
      <p style={{ marginTop: "10px", textAlign: "right" }}>
        <TextStyle variation="subdued">{t("general.searchHelp")}</TextStyle>
      </p>
    </React.Fragment>
  );
  const renderItem = (item) => {
    const { id, email, defaultAddress, displayName, firstName, lastName } =
      item;
    const { company } = defaultAddress || {};
    const name = displayName || `${firstName} ${lastName}`;

    return (
      <ResourceItem id={id}>
        <Stack alignment="center">
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
            <TextStyle variation="subdued">{company || "-"}</TextStyle>
          </Stack.Item>
          {loggedInCustomerId === id && (
            <Stack.Item>
              <Badge status="attention" children={t("customers.switched")} />
            </Stack.Item>
          )}
          <Stack.Item>
            {email ? ( // only possible with emailaddress
              <Button
                onClick={() => switchUser(id, name)}
                plain
                children={t("customers.login")}
              />
            ) : (
              <Badge status="critical" children={t("customers.noEmail")} />
            )}
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const sortOptions = [
    { label: t("customers.sort.nameAsc"), value: "NAME_ASC" },
    { label: t("customers.sort.nameDesc"), value: "NAME_DESC" },
  ];

  return !hasError ? (
    <React.Fragment>
      <ResourceList
        resourceName={resourceName}
        items={customers}
        loading={isLoading}
        renderItem={renderItem}
        filterControl={filterControl}
        sortValue={sortOrder}
        sortOptions={!hasQuery && sortOptions}
        onSortChange={(selected) => setSortOrder(selected)}
      ></ResourceList>
      <div className="navigation">
        <Pagination
          hasPrevious={hasPreviousPage}
          onPrevious={goToPreviousPage}
          hasNext={hasNextPage}
          onNext={goToNextPage}
        />
      </div>
    </React.Fragment>
  ) : (
    <EmptyState heading={t("customers.error.title")} image={ErrorImage}>
      <p>{t("customers.error.description")}</p>
    </EmptyState>
  );
};

export default withFirebase(CustomersList);
