// react
import React from "react";
import { Navigation } from "@shopify/polaris";
import { CustomersMinor, ExternalMinor } from "@shopify/polaris-icons";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AppNavigation = ({ history }) => {
  const { t } = useTranslation();

  return (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            onClick: () => {
              history.push("/");
            },
            label: t("menu.login"),
            icon: CustomersMinor,
          },
        ]}
      />
      <Navigation.Section
        separator
        title={t("menu.about").toUpperCase()}
        fill
        items={[
          {
            onClick: () => window.open("https://www.code.nl"),
            label: t("menu.developedBy"),
            icon: ExternalMinor,
          },
        ]}
      />
    </Navigation>
  );
};

export default withRouter(AppNavigation);
