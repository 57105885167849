import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import Firebase, { FirebaseContext } from "./firebase";
import Authorize from "./components/Authorize";

import enTranslations from "@shopify/polaris/locales/en.json";
import deTranslations from "@shopify/polaris/locales/de.json";
import frTranslations from "@shopify/polaris/locales/fr.json";
import nlTranslations from "@shopify/polaris/locales/nl.json";
import esTranslations from "@shopify/polaris/locales/es.json";

import { getI18n } from "react-i18next";

export const App = () => {
  // set polaris language file
  const multilangObj = getI18n();
  const currentLanguage = multilangObj.language
    ? multilangObj.language.substring(0, 2)
    : "en";
  let polarisTranslations;
  switch (currentLanguage) {
    case "de":
      polarisTranslations = deTranslations;
      break;
    case "nl":
      polarisTranslations = nlTranslations;
      break;
    case "fr":
      polarisTranslations = frTranslations;
      break;
    case "en":
    default:
      polarisTranslations = enTranslations;
      break;
    case "es":
      polarisTranslations = esTranslations;
      break;
  }

  return (
    <BrowserRouter>
      <AppProvider i18n={polarisTranslations}>
        <FirebaseContext.Provider value={new Firebase()}>
          <Authorize />
        </FirebaseContext.Provider>
      </AppProvider>
    </BrowserRouter>
  );
};
